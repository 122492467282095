import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

import Logo from './Logo'
import Media from '../utils/style-utils'

const Nav = styled.nav`
  position: relative;
  width: 1440px;
  margin: 0 auto;
  padding: 20px 50px;
  ${({ isOverlay }) =>
    isOverlay
      ? `
    margin-top: -70px;
    z-index: 1;
    transform: translateY(100%);
  `
      : `
    margin-bottom: 50px;
  `}
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;

  a {
    color: ${({ isOverlay }) => (isOverlay ? 'white' : '#f4ba48')};
    margin: 0 30px;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
  }

  a:hover,
  a.active {
    border-bottom: 2px solid orange;
  }

  a:visited {
    color: ${({ isOverlay }) => (isOverlay ? 'white' : '#2F338F')};
  }

  ${Media.handheld`
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0 0 0;
    
    ${({ isOverlay }) =>
    isOverlay
      ? `
    margin-top: -45px;
    z-index: 1;
    transform: translateY(100%);
  `
      : `
    `}
  `}

  ${Media.tablet`
    width: 100%;
  `}
`
const LogoLink = styled.div`
  img {
    margin: 0;
    max-height: 100%;
  }

  a:hover {
    border: none;
  }

  ${Media.handheld`
    margin: 1rem 0;
    margin-bottom: 1.5rem;
  `}
`
const LinkGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Media.handheld`
    padding: 0 20px;
    width: 100%;

    a {
      margin: 0;
    }
  `}
`

const Menu = ({ isOverlay, hideLogo }) => (
  <Nav isOverlay={isOverlay}>
    {hideLogo ? (
      <div />
    ) : (
      <LogoLink>
        <Logo showName={!isMobile} />
      </LogoLink>
    )}
    <LinkGroup>
      <Link to='/' activeClassName='active'>
        Home
      </Link>
      <Link to='/projects/' activeClassName='active'>
        Projects
      </Link>
      <Link to='/contact/' activeClassName='active'>
        Contact Us
      </Link>
    </LinkGroup>
  </Nav>
)

Menu.propTypes = {
  isOverlay: PropTypes.bool,
  hideLogo: PropTypes.bool
}

Menu.defaultProps = {
  isOverlay: false,
  hideLogo: false
}

export default Menu
