/* eslint-disable */
import { createGlobalStyle } from 'styled-components'

import YunghkioTTF from '../resources/fonts/yunghkio.ttf'
import YunghkioWOFF from '../resources/fonts/yunghkio.woff'
import Zawgyi from '../resources/fonts/zawgyi.woff'
import MonTTF from '../resources/fonts/mon3.ttf'
import MonWOFF from '../resources/fonts/mon3.woff'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Yungkio';
    src: url(${YunghkioWOFF}) format('woff'),
         url(${YunghkioTTF}) format('truetype');
    ;
  }

  @font-face {
    font-family:'MON3 Anonta 1';
    src:local('MON3 Anonta 1'), url(${MonWOFF}) format('woff'), url(${MonTTF}) format('ttf');
  }

  @font-face {
    font-family: 'Zawgyi';
    src: url(${Zawgyi}) format('woff');
  }
`
export { GlobalStyle, GlobalStyle as default }
