import { css } from 'styled-components'

const media = {
  handheld: (...args) => css`
    @media (max-width: 767px) {
      ${css(...args)};
    }
  `,
  handheldRetina: (...args) => css`
    @media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-resolution: 2dppx) {
      ${css(...args)};
    }
  `,
  retina: (...args) => css`
    @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-resolution: 2dppx) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (min-width: 768px) and (max-width: 1024px) {
      ${css(...args)}
    }
  `
}

export default media
