import React from 'react'
import styled from 'styled-components'

import Paragraph from './Paragraph'

const Container = styled.footer`
  text-align: center;
  background: #70e1f5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffd194,
    #70e1f5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffd194,
    #70e1f5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 1rem 0;

  a {
    font-size: 0.7rem;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:visited {
    color: inherit;
  }
`

const FooterText = styled(Paragraph)`
  font-size: 0.8rem;
`
const Footer = () => (
  <Container>
    <FooterText>
      &copy; {new Date().getFullYear()} Myo Painting Group Co., Ltd
    </FooterText>
    <div>
      <a
        href='https://app.termly.io/document/privacy-policy/722a2a06-1370-4076-9e2f-59e1083e2c77'
        target='_blank'
        rel='noreferrer nofollow noopener'
      >
        Privacy Policy
      </a>
    </div>
  </Container>
)

export default Footer
