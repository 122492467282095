import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'
// resources
import logo from '../resources/images/logo.png'

const CompanyName = styled.div`
  color: #2f338f;
  font-size: 24px;
  margin-left: 15px;
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: 'none';
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none !important;
  }
`

const Logo = ({ showName }) => (
  <StyledLink to='/'>
    <img src={logo} alt='Myo Painting Group Logo' width='90' />
    {showName && <CompanyName>Myo Painting Group</CompanyName>}
    {/* {siteTitle} */}
  </StyledLink>
)

Logo.propTypes = {
  showName: PropTypes.bool
}

Logo.defaultProps = {
  showName: false
}

export default Logo
