import React from 'react'
import styled from 'styled-components'

import Section from './Section'
import SectionTitle from './SectionTitle'
import Paragraph from './Paragraph'
import Media from '../utils/style-utils'

const ContactSection = styled(Section)`
  text-align: center;
  max-width: 100%;
  background: #70e1f5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffd194,
    #70e1f5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffd194,
    #70e1f5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`
const ContactLink = styled.a`
  display: inline-block;
  margin: 4rem 0;
  color: orange;
  font-size: 3rem;
  text-decoration: underlined;
  font-style: italic;
  text-underline-position: under;
  background-color: white;
  padding: 1.5rem 1.5rem 2rem 0;
  ${Media.handheld`
    padding: 0.8rem 0.8rem 1.3rem 0;
    font-size: 2rem;
  `};
`

const Contact = () => (
  <ContactSection>
    <SectionTitle>Contact</SectionTitle>
    <ContactLink href='mailto:info@myopainting.com'>
      info@myopainting.com
    </ContactLink>
    <Paragraph>
      No. 7F, Lanthit Road, Sawbwargyi Gone PO, Insein Township, Yangon, Myanmar
    </Paragraph>
    <Paragraph>
      Ph: (+95)9 769793150, 01 643604, 01 646185, 01 646135, 01 646136, 01
      646137
    </Paragraph>
    <Paragraph isMyanmar>
      ကျွန်တော်တို့ရဲ့ ဝန်ဆောင်မှုများနှင့် ပတ်သက်ပြီး အချိန်မရွေးဆက်သွယ်
      စုံစမ်းနိုင်ပါသည်။
    </Paragraph>
  </ContactSection>
)

export default Contact
