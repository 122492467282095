import styled from "styled-components"

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 1px;
  font-family: ${({ isMyanmar }) =>
    isMyanmar
      ? '"MON3 Anonta 1", Arial, Helvetica, sans-serif'
      : "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif"};
`

Paragraph.defaultProps = {
  isMyanamr: false
}

export default Paragraph
